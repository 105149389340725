import React, { useEffect, useState } from 'react'; // Import useEffect and useState
import ReactGA from 'react-ga4';
import './GlobalStyle.css'; // Optional: Add your CSS file for styling
import { Link } from 'react-router-dom';

function Home() {
  ReactGA.initialize('G-G6P12SH7XL');
  const clientLogos = [
    require('./assets/clients-logo/buster.png'),
    require('./assets/clients-logo/estiluz.png'),
    require('./assets/clients-logo/fornasarig.jpg'),
    require('./assets/clients-logo/giopagani.gif'),
    require('./assets/clients-logo/inarchi.gif'),
    require('./assets/clients-logo/johanson.png'),
    require('./assets/clients-logo/lapalma.gif'),
    require('./assets/clients-logo/offecct.png'),
    require('./assets/clients-logo/profim.gif'),
    require('./assets/clients-logo/prostoria.png'),
    require('./assets/clients-logo/segis.jpg'),
    require('./assets/clients-logo/sitland.jpg'),
  ];
  const [showCompanies, setShowCompanies] = useState(false); // State to control visibility
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCompanies(true); // Show companies after a delay
    }, 1000); // Delay in milliseconds (1 second)

    return () => clearTimeout(timer); // Cleanup on unmount
  }, []);

  return (
    <div className="app-container">
      <header className="app-header">
        <div className="header-logo">WTFF.com</div>
        {/*
          <nav>
            <ul>
              <li><a href="#features">Features</a></li>
              <li><a href="#pricing">Pricing</a></li>
              <li><a href="#testimonials">Testimonials</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </nav>
        */}
      </header>

      <div className="app-main">
        <section className="hero">
          <div className="hero-title">WTFF - WEB TOOLS FOR FRONTEND</div>
          <p>Innovative tools for rapid web property improvements.</p>
        </section>

        <section id="features" className="features">
          <div className="feature-item">
          <div className="feature-item-title">Create e-commerce product page content in seconds!</div>
            <p>With one request you will receive:</p>
            <ul>
              <li>Product categorization according to Google taxonomy</li>
              <li>Product photos</li>
              <li>Meta description</li>
              <li>Short description</li>
              <li>Full description</li>
              <li>And possibly a video</li>
            </ul>
            <a href="https://ecomm.wtff.com" className="start-button" target="new">Start</a>

          </div>
          <div className="feature-item">
          <div className="feature-item-title">Subtitles Center</div>
            <p>Add subtitles to any video hassle-free.</p>
            <p>Translate subtitles to 100+ languages.</p>
            <p>Edit subtitles if necessary.</p>
            <p>Simply upload your video.</p>
            <a href="https://subtitles.center" className="start-button" target="new">Start</a>
          </div>
          <div className="feature-item">
            <div className="feature-item-title">more AI solutions</div>
            <div className="text-small">
              <p>AI solutions are revolutionizing the modern web, particularly in e-commerce. Advanced algorithms personalize shopping experiences by predicting user preferences and recommending products. Chatbots enhance customer service with instant, 24/7 support, while AI-driven analytics optimize inventory management and pricing strategies.</p>
              <p>Moreover, AI improves website search functionality, making it easier for customers to find what they need quickly. These innovations not only enhance user satisfaction, but also boost sales and operational efficiency, setting new standards in the digital marketplace.</p>
            </div>
            <div className="comingsoon-button" target="new">coming soon</div>
          </div>
        </section>



      {/*
        <section id="pricing" className="pricing">
          <h2>Pricing</h2>
          <p>Choose a plan that fits your needs.</p>
          <ul>
            <li>Starter Plan</li>
            <li>Pro Plan</li>
            <li>Business Plan</li>
          </ul>
        </section>

        <section id="testimonials" className="testimonials">
          <h2>What Our Customers Say</h2>
          <blockquote>
            <p>"Kinsta is the best hosting provider I've ever used!"</p>
            <footer>- Happy Customer</footer>
          </blockquote>
        </section>
      */}

      </div>

      <div className="app-main-bottom">
        <section className="companies-list">
            <div className="companies-list-title">80+ businesses trust us, including</div>
            {showCompanies && ( // Render only if showCompanies is true
              <div className="slider">
                {clientLogos.map((logo, index) => (
                  <img key={index} src={logo} alt={`Client Logo ${index + 1}`} className="client-logo" />
                ))}
              </div>
            )}
        </section>

        <section className="knowledge-base">
              <div className="knowledge-base-title">Knowledge Base</div>
              <div className="knowledge-base-subtitle">Explore our articles to enhance your understanding and skills.</div>
              <div className="knowledge-base-articles-wrapper">
                <Link to="/create-openai-assistant" className="knowledge-link">How to Create OpenAI Assistant</Link>
              </div>

        </section>


        <footer className="app-footer">
          <p>&copy; 2024 wtff.com</p>
        </footer>
      </div>  
    </div>
  );
}

export default Home;
