import React, {useEffect} from 'react';
import image1 from './../assets/article-img/openai-assistant-create.png';
import image2 from './../assets/article-img/openai-playground.png';
import image3 from './../assets/article-img/openai-playground-1.png';
import image4 from './../assets/article-img/openai-assistant.png';

const OpenaiAssistantCreate = () => {
    useEffect(() => {
        document.title = 'How to create OpenAI assistant';
      }, []);

    return (
        <div>
            <header className="app-header">
                <div className="header-logo">WTFF.com</div>
                <div className="header-menu"><a href="https://wtff.com" rel="noopener noreferrer">Home</a></div>
            </header>
            <main className="app-main">

            <div className="article-wrapper">
            <h1>How to Create an OpenAI Assistant</h1>
            <p>Creating an OpenAI Assistant is a straightforward process, especially if you have your OpenAI API key ready.</p>
            <p>Follow the steps below to set up your assistant and start using it via the API.</p>

            <h2>Steps to Create Your OpenAI Assistant</h2>
            <ol>
                <li>
                    <strong>Go to the OpenAI Platform:</strong>
                    <p>Visit <a href="https://platform.openai.com/login?launch" target="_blank" rel="noopener noreferrer">OpenAI Platform</a> and authenticate your account.</p>
                </li>
                <li>
                    <strong>Click on 'API':</strong>
                    <p>Once logged in, navigate to the 'API' section in the dashboard.</p>
                    <img src={image1} alt="API Section" className="step-image" />
                </li>
                <li>
                    <strong>Click on Playground:</strong>
                    <p>In the API section, find and click on 'Playground' to start creating your assistant.</p>
                    <img src={image2} alt="Playground" className="step-image" />
                </li>
                <li>
                    <strong>Click on Assistant:</strong>
                    <p>In the left menu, find and click on 'Assistant' to open new assistant form.</p>
                    <img src={image3} alt="Playground Assistant" className="step-image" />
                </li>
                <li>
                    <strong>Add a Name:</strong>
                    <p>Provide a name for your assistant. Below the name field, you will see the Assistant ID generated for you.</p>
                    <img src={image4} alt="Add Name" className="step-image" />
                </li>
                <li>
                    <strong>Add System Instructions:</strong>
                    <p>Define what you want your assistant to do by adding system instructions. This will guide the assistant's behavior and responses.</p>
                </li>
                <li>
                    <strong>Select Model:</strong>
                    <p>Choose the appropriate model for your assistant based on your requirements.</p>
                </li>
                <li>
                    <strong>Select 'File Search':</strong>
                    <p>Enable the 'File search' option to allow your assistant to access and utilize files.</p>
                </li>
                <li>
                    <strong>Click '+Files':</strong>
                    <p>Add your dataset(s) by clicking on '+Files'. After uploading, you will see the Vector Store ID generated for your dataset.</p>
                </li>
            </ol>

            <h2>Accessing Your OpenAI Assistant via API</h2>
            <p>Now, that you have set up your OpenAI Assistant, you can access it via the API using the following credentials:</p>
            <ul>
                <li>OpenAI API Key</li>
                <li>Assistant ID</li>
                <li>Vector Store ID</li>
            </ul>


            </div>
            <footer className="app-footer">
                <p>&copy; 2024 wtff.com</p>
            </footer>

            </main> 
             
        </div>
        
    );
};

export default OpenaiAssistantCreate;