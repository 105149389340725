import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router,  Routes,  Route} from "react-router-dom"
import './GlobalStyle.css';
import Home from './Home';
import OpenaiAssistantCreate from './articles/OpenaiAssistantCreate'
import "typeface-bebas-neue";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>  
        <Routes>
          <Route index element={<Home/>} />
          <Route path="/create-openai-assistant" element={<OpenaiAssistantCreate/>} />
        </Routes>
    </Router> 
  </React.StrictMode>

);
